<template>
  <div>
    <b-button
      size="sm"
      class="button-export-style ml-2 pl-2 pr-2 new-color-export-facture"
      title="Télecharger xls facture "
      v-b-modal.extract-modal-obligee-facture
    >
      <font-awesome-icon icon="file-export"
    /></b-button>
    <!-- Extract OBLIGEE FACTURE Modal -->
    <b-modal
      ref="extract-modal-obligee-facture"
      id="extract-modal-obligee-facture"
      no-close-on-backdrop
      :hide-footer="true"
      :title="'Générer un xls'"
      @hidden="hideModal('extract-modal-obligee-facture')"
      modal-class="modal-extaction-bdd-frais"
    >
      <b-form-group
        id="fieldset-horizontal"
        label-cols-sm="5"
        label-cols-lg="3"
        content-cols-sm
        content-cols-lg="9"
        label="Modèle : "
        label-for="extract-modal"
      >
        <b-form-select
          id="extract-modal"
          v-model="selectedModel"
          :options="ListModelToExtract"
          required
          class="b-form-select-raduis "
        ></b-form-select>
      </b-form-group>
      <div class="form-modal-custom-style mt-2">
        <div class="messageError">
          <div v-if="errorAppeliPaiementThObligeeExportXls" class="error">
            <ul v-if="Array.isArray(errorAppeliPaiementThObligeeExportXls)">
              <li
                v-for="(e, index) in errorAppeliPaiementThObligeeExportXls"
                :key="index"
              >
                {{ e }}
              </li>
            </ul>
            <span v-else>{{ errorAppeliPaiementThObligeeExportXls }}</span>
          </div>
        </div>
        <div class="actionModel">
          <b-button
            class="button-valide-style "
            @click="handleExport"
            :disabled="chargementAppelPaiementThObligeeExportXlsFacture"
          >
            <span>
              Générer
              <div
                v-if="chargementAppelPaiementThObligeeExportXlsFacture"
                class="loading ml-2"
              >
                <div class="spinner-border" role="status"></div></div
            ></span>
          </b-button>
        </div>
      </div>
    </b-modal>
    <!-- END Extract OBLIGEE FACTURE Modal -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    resetModalExportFacture: { required: true }
  },
  data() {
    return {
      selectedModel: 'vertical',
      ListModelToExtract: [
        { value: 'vertical', text: 'Verticale' },
        { value: 'horizontal', text: 'Horizontale' }
      ]
    };
  },
  methods: {
    handleExport() {
      this.$emit('exportFactureXls', this.selectedModel);
    },
    resetModal(ref) {
      this.hideModal(ref);
      this.selectedModel = 'vertical';
    },
    hideModal(ref) {
      this.$refs[ref].hide();
    }
  },
  computed: {
    ...mapGetters([
      'chargementAppelPaiementThObligeeExportXlsFacture',
      'errorAppeliPaiementThObligeeExportXls'
    ])
  },
  watch: {
    resetModalExportFacture() {
      this.resetModal('extract-modal-obligee-facture');
    }
  }
};
</script>

<style scoped lang="scss">
.new-color-export-facture {
  background-color: #0e0c5e;
}
</style>
